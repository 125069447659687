<template>
  <canvas id="canvas" @click="showNum" width="138" height="36"></canvas>
</template>
<script>
import { onMounted } from 'vue';
export default {
    name: "VerificationCode",
    setup(props,context) {
        let num = [];
        let number = '';
        onMounted(()=>{       
            draw(num);
            number =num.join().replace(/,/g,'');
            context.emit('verificationCodeEmit', number);
        });
        function draw(num) {
          let canvas_width=138;
          let canvas_height=36;
          let canvas =document.getElementById("canvas");//获取到canvas的对象，演员
          let context =canvas.getContext("2d");//获取到canvas画图的环境，演员表演的舞台

          canvas.width =canvas_width;
          canvas.height =canvas_height;

          let sCode ="A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,U,V,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
          let aCode =sCode.split(",");
          let aLength =aCode.length;//获取到数组的长度

          for (let i =0;i <=3;i++) {
            let j =Math.floor(Math.random() *aLength);//获取到随机的索引值
            let deg =Math.random() *15 *Math.PI /180;//产生0~30之间的随机弧度
            let txt =aCode[j];//得到随机的一个内容

            //num[i] = txt.toLowerCase(); toUpperCase()
            num[i] =txt;

            let x =20 +i *30;//文字在canvas上的x坐标
            let y =20 +Math.random() *8;//文字在canvas上的y坐标
            context.font ="bold 23px 微软雅黑";
            context.translate(x,y);
            context.rotate(deg);
            context.fillStyle =randomColor();
            context.fillText(txt,0,0);
            context.rotate(-deg);
            context.translate(-x, -y);
          }
          for (let i =0;i <=5;i++) {//验证码上显示线条
            context.strokeStyle =randomColor();
            context.beginPath();
            context.moveTo(Math.random() *canvas_width,Math.random() *canvas_height);
            context.lineTo(Math.random() *canvas_width,Math.random() *canvas_height);
            context.stroke();
          }
          for (let i =0;i <=30;i++) {//验证码上显示小点
            context.strokeStyle =randomColor();
            context.beginPath();
            let x =Math.random() *canvas_width;
            let y =Math.random() *canvas_height;
            context.moveTo(x,y);
            context.lineTo(x +1,y +1);
            context.stroke();
          }
        };
        //得到随机的颜色值
        function randomColor() {
          let r =Math.floor(Math.random() *256);
          let g =Math.floor(Math.random() *256);
          let b =Math.floor(Math.random() *256);
          return "rgb(" +r +"," +g +"," +b +")";
        };
        /*点击更换-验证码*/
        const showNum=()=>{
          draw(num);
          number =num.join().replace(/,/g,'');
          context.emit('verificationCodeEmit', number);
        };

        return {
          num,
          number,
          showNum
        }
      },

    }
</script>

<style scoped>

</style>
