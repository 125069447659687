<template>
  <div class="login">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">登录</div>
    <img class="bg" src="../../assets/img/loginbg.png" alt="">
    
    <div class="login-card">
        <div class="catalog">
            <div :class="[{ blue: loginInfo.visible ==  '1'}, 'div']" @click="changeLogin(1)">用户名登录</div>
            <div :class="[{ blue: loginInfo.visible ==  '2'}, 'div']" @click="changeLogin(0)">企业名登录</div>
        </div>
        <div class="catalog">
            <img src="../../assets/img/login_icon1.png" alt="">
            <input type="text" :placeholder="loginInfo.visible == 1?'用户名':'企业名称'" v-model="loginInfo.userName">
        </div>
        <div class="catalog">
            <img src="../../assets/img/login_icon2.png" alt="">
            <input type="password" placeholder="输入密码" v-model="loginInfo.password">
        </div>
        <div class="catalog">
            <img src="../../assets/img/login_icon3.png" alt="">
            <input style="width: 50%" type="text" placeholder="输入验证码" v-model="inputCode">
            <VerificationCode class="verify" @verificationCodeEmit="verificationCodeEmit"></VerificationCode>
        </div>
        <div class="agree">
        <van-checkbox v-model="checked" shape="square" icon-size="3.5vw">&nbsp;</van-checkbox>
        <p>我已阅读并同意<span class="blue" @click="openService">《用户服务协议》</span>和<span class="blue" @click="openPrivacy">《隐私政策》</span></p>      
        
        </div>
        <button class="login-btn" @click="login">登录</button>
        <div class="login-footer flex-between">
            <router-link to="/logins" class="gray">现在注册</router-link>
            <router-link :to="{path: '/passwordForget', query: { type: loginInfo.visible }}" class="gray">忘记密码</router-link>
        </div>

    </div>
        <!-- 服务协议 -->
    <van-popup round v-model:show="showService">
        <div class="pop">
            <div class="pop-header">用户服务协议</div>
            <div class="pop-content">
                一、授权形式与期限<br/>
                （一）授权方知悉并同意本授权书以数据电文形式订立。<br/>
（二）内容自授权方在线确认本授权书（即勾选“同意并接受”《企业信用报告查询授权书》并点击“确定”按钮）之日起生效，有效期持续至授权方在被授权方申请（参与）办理的所有授信类金融产品终止之日止或者授权方明确的授权结束之日止。
                <br/><br/>
                二、授权内容
<br/>
授权方同意并不可撤销的向被授权方郑重授权查询、使用、留档以下内容：
<br/>
（一）包括但不限于企业相关政务信息：
<br/>
1.企业基本信息：营业执照信息、股东信息、高管人员、对外投资（企业对外投资、法人对外投资、法人对外任职）、企业变更记录、企业联系方式。
<br/>
2.司法风险：开庭公告、法律诉讼、法院公告、被执行人、失信信息、犯罪信息记录、立案信息、检察信息、司法协助、法院相关信息。
<br/>
3.经营风险：经营异常、行政处罚、严重违法、抽查检查、股权出质、动产抵押、欠税公告、环保处罚、税收违法、简易注销、股权冻结、清算信息、其他违规行为、经营欠费风险。
<br/>
4.经营信息：税务评级、财务数据、招投标信息、资质证书、行政许可、进出口登记信息、企业纳税信息、企业资产信息、企业社保缴纳信息、企业公积金缴纳信息、政采中标信息、其他经营信息。
<br/>
5.知识产权：商标信息、专利信息、软件著作权、作品著作权、科技奖励、网站备案、电信许可。
<br/>
6.包括但不限于与授权方收支流水相关的企业基本信息、企业收支账户信息、企业账户收支流向信息。
<br/>
7.包括但不限于农户信用报告、农户信息评级等农户库信息。
               <br/><br/>
               三、其他事项<br/>
（一）本协议履行过程中发生的争议，双方应友好协商，协商不成时，任何一方均可向平台所在地有管辖权的人民法院起诉。<br/>
（二）授权企业在此声明已知悉并理解本授权委托书，以及因提供非公开信息及负面信息可能导致的任何不利后果。<br/>
（三）本授权书是授权方向被授权方做出的单方承诺，在授权期限内不可撤销。
                <br/>
            </div>
            <div class="pop-footer">
                <div class="back" @click="closePop">确认</div>
            </div>
        </div>
    </van-popup>
        <!-- 隐私政策 -->
    <van-popup round v-model:show="showPrivacy">
        <div class="pop">
            <div class="pop-header">隐私政策</div>
            <div class="pop-content">
                一、授权形式与期限<br/>
                （一）授权方知悉并同意本授权书以数据电文形式订立。<br/>
（二）内容自授权方在线确认本授权书（即勾选“同意并接受”《企业信用报告查询授权书》并点击“确定”按钮）之日起生效，有效期持续至授权方在被授权方申请（参与）办理的所有授信类金融产品终止之日止或者授权方明确的授权结束之日止。
                <br/><br/>
                二、授权内容
<br/>
授权方同意并不可撤销的向被授权方郑重授权查询、使用、留档以下内容：
<br/>
（一）包括但不限于企业相关政务信息：
<br/>
1.企业基本信息：营业执照信息、股东信息、高管人员、对外投资（企业对外投资、法人对外投资、法人对外任职）、企业变更记录、企业联系方式。
<br/>
2.司法风险：开庭公告、法律诉讼、法院公告、被执行人、失信信息、犯罪信息记录、立案信息、检察信息、司法协助、法院相关信息。
<br/>
3.经营风险：经营异常、行政处罚、严重违法、抽查检查、股权出质、动产抵押、欠税公告、环保处罚、税收违法、简易注销、股权冻结、清算信息、其他违规行为、经营欠费风险。
<br/>
4.经营信息：税务评级、财务数据、招投标信息、资质证书、行政许可、进出口登记信息、企业纳税信息、企业资产信息、企业社保缴纳信息、企业公积金缴纳信息、政采中标信息、其他经营信息。
<br/>
5.知识产权：商标信息、专利信息、软件著作权、作品著作权、科技奖励、网站备案、电信许可。
<br/>
6.包括但不限于与授权方收支流水相关的企业基本信息、企业收支账户信息、企业账户收支流向信息。
<br/>
7.包括但不限于农户信用报告、农户信息评级等农户库信息。
               <br/><br/>
               三、其他事项<br/>
（一）本协议履行过程中发生的争议，双方应友好协商，协商不成时，任何一方均可向平台所在地有管辖权的人民法院起诉。<br/>
（二）授权企业在此声明已知悉并理解本授权委托书，以及因提供非公开信息及负面信息可能导致的任何不利后果。<br/>
（三）本授权书是授权方向被授权方做出的单方承诺，在授权期限内不可撤销。
                <br/>
            </div>
            <div class="pop-footer">
                <div class="back" @click="closePop">确认</div>
            </div>
        </div>
    </van-popup>
  </div>
</template>
<script>
import { ref,reactive,toRaw } from 'vue';
import { Toast } from 'vant';
import { useStore } from 'vuex';
import {useRouter} from 'vue-router';
import { apiLogin } from '../../api/axios';
import VerificationCode from '../../components/VerificationCode.vue'
export default {
  setup() {
    let store = useStore();
    let router = useRouter();
    const loginInfo = reactive({userName: '',password: '',visible: '1'});
    let verificationCode='';
    function verificationCodeEmit (code) {
        // console.log('父组件接收到的验证码',code);
      verificationCode = code;
    }
    const checked = ref(false);
    const inputCode = ref('');
    const changeLogin = (i) => {
        if(i) {
            loginInfo.visible = '1'
        }else {
            loginInfo.visible = '2'
        }
    }
    const showService = ref(false);
    const openService= () => {
        showService.value = true;
    };
    const showPrivacy = ref(false);
    const openPrivacy= () => {
        showPrivacy.value = true;
    };
    const closePop= () => {
        showService.value = false;
        showPrivacy.value = false;
    };
    const login= () => {
        if(!checked.value) {
            Toast('请先阅读用户服务协议');
            return
        }
        if(inputCode.value.toLowerCase() == verificationCode.toLowerCase()) {
            apiLogin(toRaw(loginInfo)).then(res => {
            // 获取数据成功后的其他操作
           console.log(res,'获取的数据')
           switch(res.code) {
                case '6':                    
                    if (typeof(Storage) !== "undefined") {
                            //储存/更新token到本地localStorage及store
                            localStorage.setItem("gxrToken", JSON.stringify(res.info));
                            store.dispatch('setToken',res.info);
                            router.go(-1);
                        } else {
                            console.log("抱歉！您的浏览器不支持 Web Storage ...");
                        }                
                    break;
                case '0':
                    Toast('缺失参数或参数为空');
                    break;
                case '1':
                    Toast('用户名/企业名称或密码错误次数超过限制，请10分钟后再尝试');
                    break;
                case '2':
                    Toast('用户名/企业名称或密码有误');
                    break;
                case '3':
                    Toast('该账号已失效');
                    break;
                case '4':
                    Toast('距离上次修改密码已超过规定时间，请重新修改密码再登录');
                    break;
                case '5':
                    Toast('不是企业用户或政府用户');
                    break;
                default:
                    Toast('其他错误，请联系我们');
            }                 

        }).catch(err=>{
            Toast('请求失败');
        })
        }else {
            Toast('验证码输入错误');
            return
        }

    };
    return {
        loginInfo,
        verificationCodeEmit,
        changeLogin,
        checked,
        inputCode,
        showService,
        openService,
        showPrivacy,
        openPrivacy,
        closePop,
        login
    }
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  },
  components: {
    VerificationCode
  }
}
</script>
<style lang="less" scoped>
.login {
    .navTitle {
        background: none;
    }
    img.bg {
        width: 100%;
        height: auto;
    }
    .login-card {
        margin: 0 14vw;
        margin-top: 10vw;
        .catalog {
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0,0,0,.05);
            padding: 2vw 0;
            .div {
                width: 50%;
                text-align: center;
                font-size: 4vw;
                color: #666;
                padding: 3vw 0;
                border-bottom: 1px solid rgba(0,0,0,.05);
            }
            .blue {
                color: #65b8fc;
                border-bottom: 1px solid #65b8fc;
            }
            img {
                width: 5vw;
                height: auto;
            }
            input {
                background: transparent;
                border: 0;
                margin-left: 2vw;
                width: 61vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
            }
            .verify {
                width: 25vw;
                height: 8vw;
            }
            
        }
        .catalog:first-child {
            padding: 0;
        }
        .login-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4.5vw;
            padding: 3vw 6vw;
            border-radius: 7vw;
            border: 0;
            margin-top: 8vw;
            width: 100%;
            letter-spacing: 2vw;
        }
        .agree {
            margin-top: 4vw;
            font-size: 3.5vw;
            color: #666;
            display: flex;
            align-items: baseline;
           
        }
    
    }
    .blue {
            color: #3399ff
        }
        .gray {
            color: #666666;
        }
    .login-footer {
        margin-top: 4vw;        
        font-size: 4vw;
        
    }
    .pop {
        padding: 4vw;
        width: 70vw;
        .pop-header {
            font-size: 5vw;
            font-weight: 600;
            text-align: center;
            margin-bottom: 4vw;
        }
        .pop-content {
            width: 100%;
            height: 60vh;
            font-size: 4vw;
            overflow-y: auto;
            line-height: 6vw;
            letter-spacing: 1px;
        }
        .pop-footer {
            text-align: center;padding-top: 4vw;
            .back {
                display: inline-block;
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                padding: 1.5vw 0;
                border-radius: 5vw;
                width: 22vw;
            }
        }
    }
    
}
</style>
